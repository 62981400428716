<template>
  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Character Equipments</h5>
          <span aria-hidden="true" role="button" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="container mb-3">
            <div class="row">
              <button class="btn btn-primary" @click="showAdd = true" v-if="data.isAdd">Add Equipment</button>
              <!-- <button class="btn btn-primary" @click="this.getCharacters()">View</button> -->
            </div>
          </div>
          <data-table2 :api="getEquipments" :actions="actions" :headers="headers" :fields="['equipment_name']"
            :trigger="trigger" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="close">Close</button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="showAdd"> <!--Add Equipment-->
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Equipment</h5>
          <span aria-hidden="true" role="button" @click="closeAdd">&times;</span>
        </div>
        <div class="modal-body">
          <!--Equipment Types-->
          <div class="row">
            <div class="btn-group" data-toggle="buttons">
              <label v-for="(value, key) in equipmentType" :key="key" class="btn btn-secondary"
                :class="{ active: EquipmentFilter.selectedEquipmentType === value }">
                <input type="radio" v-model="EquipmentFilter.selectedEquipmentType" :value="value"
                  @change="this.toggleEquipmentType()">{{ key }}
              </label>
            </div>
          </div>
          <!-- Equipable Type -->
          <div class="row py-2"
            v-if="EquipmentFilter.selectedEquipmentType === equipmentType.Costume || EquipmentFilter.selectedEquipmentType === equipmentType.Equipable">
            <div class="row">
              <div class="col" v-for="(value, key) in equipableType" :key="key">
                <div class="form-check" v-if="EquipmentFilter.selectedEquipmentType === equipmentType.Costume">
                  <input class="form-check-input" type="checkbox" :value="value" :id="`chk_${key}`"
                    :checked="EquipmentFilter.selectedEquipableTypes.includes(value)"
                    @change="toggleEquipableType(value)">
                  <label class="form-check-label" :for="`chk_${key}`">{{ key }}</label>
                </div>
                <div class="form-check"
                  v-if="EquipmentFilter.selectedEquipmentType === equipmentType.Equipable && (value != 4) && (value != 5)">
                  <input class="form-check-input" type="checkbox" :value="value" :id="`chk_${key}`"
                    :checked="EquipmentFilter.selectedEquipableTypes.includes(value)"
                    @change="toggleEquipableType(value)">
                  <label class="form-check-label" :for="`chk_${key}`">{{ key }}</label>
                </div>
              </div>
            </div>
          </div>
          <!-- Classes -->
          <div class="row py-3"
            v-if="(weaponEquipable || EquipmentFilter.selectedEquipmentType === equipmentType.Scroll) && (EquipmentFilter.selectedEquipmentType === equipmentType.Scroll || EquipmentFilter.selectedEquipmentType === equipmentType.Equipable)">
            <div class="btn-group row" role="group" aria-label="Basic checkbox toggle button group">
              <div class="col" v-for="(value, key) in classType" :key="key">
                <input type="checkbox" class="btn-check w-100" :id="`chk_${key}`" autocomplete="off" :value="value"
                  :checked="EquipmentFilter.selectedClass.includes(value)" @change="toggleClass(value)">
                <label class="btn btn-outline-primary w-100" :for="`chk_${key}`">{{ key }}</label>
              </div>
            </div>
          </div>
          <!-- Equipment StatType -->
          <div class="row"
            v-if="(weaponEquipable || EquipmentFilter.selectedEquipmentType === equipmentType.Scroll) && (EquipmentFilter.selectedEquipmentType === equipmentType.Scroll || EquipmentFilter.selectedEquipmentType === equipmentType.Equipable)">
            <div class="btn-group row" role="group" aria-label="Basic checkbox toggle button group">
              <div class="col" v-for="(value, key) in statType" :key="key">
                <input type="checkbox" class="btn-check w-100" :id="`chk_${key}`" autocomplete="off" :value="value"
                  :checked="EquipmentFilter.selectedStats.includes(value)" @change="toggleStats(value)">
                <label class="btn btn-outline-danger w-100" :for="`chk_${key}`">{{ key }}</label>
              </div>
            </div>
          </div>

          <div class="form-group pt-3">
            <label>Equipment</label>
            <input type="text" class="form-control" name="inpsel_equipment_id" autocomplete="off"
              placeholder="Equipment Name" @input="this.updateEquipmentId()" @change="this.updateEquipmentId()"
              v-model="this.selectedEquipment" list="equipment-list" id="inpsel_equipid">
            <datalist id="equipment-list">
              <option :value="equipment.name" :data-id="equipment.id" v-for="equipment in this.equipmentList"
                :key="equipment">{{ equipment.name }}</option>
            </datalist>
          </div>
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="model.isNonDrop" v-model="model.isNonDrop">
                  <label class="form-check-label" for="chk_ind">Is Non Drop</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :value="EquipmentFilter.isExpiration"
                    v-model="EquipmentFilter.isExpiration">
                  <label class="form-check-label" for="chk_ind">Has Expiration</label>
                </div>
              </div>
            </div>
          </div>
          <div class="stat-group"
            v-if="EquipmentFilter.selectedEquipmentType === equipmentType.Costume || EquipmentFilter.selectedEquipmentType === equipmentType.Equipable">
            <p class="fs-5 mt-3">Equipment Stats</p>
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Health Points (max:153,min:0)</label>
                    <input type="number" id="inp_hp" class="form-control" v-model="this.model.hp">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Energy Points</label>
                    <input type="number" id="inp_energ" class="form-control" v-model="this.model.energy">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Level (max: 9, min: 0)</label>
                    <input type="number" id="inp_level" class="form-control" v-model="this.model.level">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Random Value(RV) (max: 8, min: 0)</label>
                    <input type="number" id="inp_rv" class="form-control" v-model="this.model.rv">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Critical Chance (max: 15, min: 0)</label>
                    <input type="number" id="inp_critrate" class="form-control" v-model="this.model.critRate">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Hit Chance (max: 7, min: -7)</label>
                    <input type="number" id="inp_hitrate" class="form-control" v-model="this.model.hitRate">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Evasion(max: 7, min: -7)</label>
                    <input type="number" id="inp_evasion" class="form-control" v-model="this.model.evasion">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Magic Defense(max: 3)</label>
                    <input type="number" id="inp_magdef" class="form-control" v-model="this.model.magicDefense">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Element (fire/blaze: 1,electric/lightning: 0, ice/frozen: 3, poison/venom:4)</label>
                    <input type="number" id="inp_element" class="form-control" v-model="this.model.element">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Element Level (max:1,min:0)</label>
                    <input type="number" id="inp_elementlvl" class="form-control" v-model="this.model.elementLevel">
                  </div>
                </div>
              </div>
            </div>
            <p class="fs-5 mt-3">Elemental Resistance (max: 15, min: 0)</p>
            <div class="container">
              <div class="row">
                <div class="col" v-for="elementRes in this.elementResistance" :key="elementRes">
                  <div class="form-group">
                    <label class="text-capitalize">{{ elementRes }}</label>
                    <input type="number" :id='`inp_${elementRes}res`' class="form-control"
                      v-model="this.model[elementRes]">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-3">
            <div class="form-group" v-if="!EquipmentFilter.isExpiration">
              <label>Quantity</label>
              <input type="number" id="inp_qty" class="form-control" required v-model="this.model.quantity">
            </div>
            <div class="form-group" v-if="EquipmentFilter.isExpiration">
              <label>Expiration</label>
              <select name="inp_exp" class="form-control" id="inp_exp" required v-model="this.expiryTicks"
                @change="this.computeAdditionalTicks(this.expiryTicks)">
                <option :value="this.calculateTicksFromHours(value)" v-for="(value, key) in this.expirationSelect"
                  :key="key">{{ key.replace('_', '') }}</option>
              </select>
            </div>

          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeAdd">Close</button>
          <button type="button" class="btn btn-success" @click="addEquipment">Add Equipment</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="equipmentToUpdate.show">
    <!--Update Equipment-->
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Updating {{ this.equipmentToUpdate.entity.equipment_name }} Stats</h5>
          <span aria-hidden="true" role="button" @click="closeUpdate">&times;</span>
        </div>
        <div class="modal-body">
          <hr>
          <div class="stat-group">
            <p class="fs-5">Equipment Stats</p>
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <input class="form-check-input" type="checkbox" :value="this.equipmentToUpdate.entity.isNonDrop"
                      v-model="this.equipmentToUpdate.entity.isNonDrop">
                    <label class="form-check-label" for="chk_ind">Is Non Drop</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Health Points (max: 153, min:0)</label>
                    <input type="number" id="inp_hp" class="form-control" v-model="this.equipmentToUpdate.entity.hp">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Energy Points</label>
                    <input type="number" id="inp_energ" class="form-control"
                      v-model="this.equipmentToUpdate.entity.energy">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Level (max: 9, min: 0)</label>
                    <input type="number" id="inp_level" class="form-control"
                      v-model="this.equipmentToUpdate.entity.level">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Random Value(RV) (max: 8, min: 0)</label>
                    <input type="number" id="inp_rv" class="form-control" v-model="this.equipmentToUpdate.entity.rv">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Critical Rate (max: 15, min: 0)</label>
                    <input type="number" id="inp_critrate" class="form-control"
                      v-model="this.equipmentToUpdate.entity.critRate">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Hit Rate (max: 7, min: -7)</label>
                    <input type="number" id="inp_hitrate" class="form-control"
                      v-model="this.equipmentToUpdate.entity.hitRate">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Evasion (max: 7, min: -7)</label>
                    <input type="number" id="inp_evasion" class="form-control"
                      v-model="this.equipmentToUpdate.entity.evasion">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Magic Defense (max: 3, min:0)</label>
                    <input type="number" id="inp_magdef" class="form-control"
                      v-model="this.equipmentToUpdate.entity.magicDefense">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Element (fire/blaze: 1,electric/lightning: 2, ice/frozen: 3, poison/venom:4)</label>
                    <input type="number" id="inp_element" class="form-control"
                      v-model="this.equipmentToUpdate.entity.element">
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Element Level (max:1, min: 0)</label>
                    <input type="number" id="inp_elementlvl" class="form-control"
                      v-model="this.equipmentToUpdate.entity.elementLevel">
                  </div>
                </div>
              </div>
            </div>
            <p class="fs-5">Elemental Resistance (max: 15, min: 0)</p>
            <div class="container"> <!--Equipment Elemental Stats-->
              <div class="row">
                <div class="col" v-for="elementRes in this.elementResistance" :key="elementRes">
                  <div class="form-group">
                    <label class="text-capitalize">{{ elementRes }}</label>
                    <input type="number" :id='`inp_${elementRes}res`' class="form-control"
                      v-model="this.equipmentToUpdate.entity[elementRes]">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" v-if="this.equipmentToUpdate.entity.expiration === 0">
            <label>Quantity</label>
            <input type="number" id="inp_qty" class="form-control" v-model="this.equipmentToUpdate.entity.quantity"
              required>
          </div>
          <div class="form-group" v-if="this.equipmentToUpdate.entity.expiration > 0">
            <label>Change Current Expiration Date </label> <span
              class="text-danger">{{ this.ticksToDate(this.equipmentToUpdate.entity.expiration) }}</span>
            <select name="inp_exp" class="form-control" id="inp_exp" required v-model="this.expiryTicks"
              @change="this.computeAdditionalTicks(this.expiryTicks)">
              <option :value="this.calculateTicksFromHours(value)" v-for="(value, key) in this.expirationSelect"
                :key="key">{{ key.replace('_', '') }}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeUpdate">Close</button>
          <button type="button" class="btn btn-success" @click="updateEquipment">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal bg-opacity-50 bg-black" tabindex="-1" role="dialog" v-if="showError"> <!--Add Equipment-->
    <div class="modal-dialog modal-sm modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Validation Error</h5>
          <span aria-hidden="true" role="button" @click="showError = false">&times;</span>
        </div>
        <div class="modal-body">
          <ul class="text-danger">
            <li v-for="error in this.errorList" :key="error">{{ error }}</li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="showError = false">Close</button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
  import DataTable2 from "data-table/src/components/DataTable2";
  import ToastService from 'toast/src/services/toast'
  import ModalService from 'modal/src/services/Modal'

  export default {
    name: "EquipmentsModal",
    data() {
      return {
        trigger: {},
        showAdd: false,
        showReward: false,
        showError: false,
        errorList: [],
        equipmentList: [],
        headers: {
          id: "ID",
          equipment_name: "Equipment Name",
          quantity: 'Quantity',
          expirationDate: 'Expiration Date',
        },
        actions: [
          {
            classNames: "fa-solid fa-pen-to-square text-success",
            callback: this.updateEquipments
          }
        ],
        equipmentData: {
          show: false, entity: null,
        },
        equipmentToUpdate: {
          show: false, entity: null,
        },
        equipmentType: {
          Equipable: 0, Pots: 1, Scroll: 2, Upgrade: 3, Card: 4, Box: 5, Costume: 7, Bundle: 8, Food: 10, Others: 13
        },
        equipableType: {
          Armor: 0, Shoes: 1, Pants: 2, Necklace: 3, Weapon: 6, Ring: 7, Earing: 8, Vehicle: 9, Pet: 10, Style: 4, Wings: 5
        },
        classType: {
          Swordsman: 0, Archer: 1, Brawler: 2, Shaman: 3, Gunner: 4, Assassin: 5
        },
        statType: {
          Strength: 0, Agility: 1, Intelligence: 2, ETC: 3
        },
        expirationSelect: {
          _1hour: 1, _2hours: 1, _5hours: 5, _7hours: 7, _12hours: 12, _1day: 24, _3days: 72, _5days: 120, _10days: 240, _15days: 360, _1month: 720, _2months: 1440, _3months: 2160
        },
        elementResistance: ['fire', 'ice', 'poison', 'electric'],
        model: {
          hp: 0,
          energy: 0,
          level: 0,
          rv: 0,
          critRate: 0,
          hitRate: 0,
          evasion: 0,
          magicDefense: 0,
          element: 0,
          elementLevel: 0,
          fire: 0,
          ice: 0,
          poison: 0,
          electric: 0,
          quantity: 1,
          expiration: 0,
          isNonDrop: false,
          isEquiped: false,
        },
        expiryTicks: Number,
        skillEquipments: [],
        EquipmentFilter: {
          selectedEquipmentType: 0,
          selectedEquipableTypes: [],
          selectedClass: [],
          selectedStats: [],
          isExpiration: false,
        },
        selectedEquipment: '',
        epochticks: 621355968000000000 //Workaround for js datetime, which starts at january, 1 1970 while c# starts at january 1, 0001
      }
    },
    props: {
      data: {
        entity: Object,
        show: Boolean
      },
      api: Object,
      server: Number
    },
    components: {
      DataTable2
    },
    computed: {
      weaponEquipable() {
        return this.EquipmentFilter.selectedEquipableTypes.some(item => item["equipable.type"] === 6);
      }
    },
    methods: {
      toggleEquipableType(typeName) {
        let value = { 'equipable.type': typeName };
        if (this.EquipmentFilter.selectedEquipableTypes.some(type => type['equipable.type'] === typeName)) {
          this.EquipmentFilter.selectedEquipableTypes = this.EquipmentFilter.selectedEquipableTypes.filter(type => type['equipable.type'] !== typeName);
        } else {
          this.EquipmentFilter.selectedEquipableTypes.push(value);
        }
        this.EquipmentFilter.selectedClass = []
        this.EquipmentFilter.selectedStats = []
        this.popEquipments();
      },

      toggleClass(className) {
        let field = (this.EquipmentFilter.selectedEquipmentType !== this.equipmentType.Scroll) ? 'equipable.hero_id' : 'hero_id';
        let value = { [field]: className };
        if (this.EquipmentFilter.selectedClass.some(type => type[[field]] === className)) {
          this.EquipmentFilter.selectedClass = this.EquipmentFilter.selectedClass.filter(type => type[[field]] !== className);
        } else {
          this.EquipmentFilter.selectedClass.push(value);
        }
        this.popEquipments();
      },

      toggleStats(statName) {
        let field = (this.EquipmentFilter.selectedEquipmentType !== this.equipmentType.Scroll) ? 'equipable.statsType' : 'skillStats';
        let value = { [field]: statName };
        if (this.EquipmentFilter.selectedStats.some(type => type[[field]] === statName)) {
          this.EquipmentFilter.selectedStats = this.EquipmentFilter.selectedStats.filter(type => type[[field]] !== statName);
        } else {
          this.EquipmentFilter.selectedStats.push(value);
        }
        this.popEquipments();
      },

      toggleEquipmentType() {
        //to reset all filterConditions
        console.log('changed')
        this.resetAll()
        this.popEquipments();
      },

      async getEquipments(filter, skip, limit) {
        let page = skip || 0
        let pageSize = limit || 10
        let equipments = await this.api.charequipment.getEquipments(this.data.entity.id, this.server, filter, page, pageSize);
        equipments.data.forEach(equipment => {
          equipment['expirationDate'] = (equipment.expiration) ? this.ticksToDate(equipment.expiration) : 'Non-expiry'
        });
        return equipments
      },

      async popEquipments() {
        if (this.EquipmentFilter.selectedEquipmentType !== this.equipmentType.Equipable && this.EquipmentFilter.selectedEquipmentType !== this.equipmentType.Costume) {
          this.EquipmentFilter.selectedEquipableTypes = [];
        }
        const filterObject = {
          where: {
            $and: [
              { equipmentType: this.EquipmentFilter.selectedEquipmentType },
              this.EquipmentFilter.selectedEquipableTypes.length > 0 ? { $or: this.EquipmentFilter.selectedEquipableTypes } : {},
              (this.EquipmentFilter.selectedClass.length > 0 && this.EquipmentFilter.selectedEquipmentType != this.equipmentType.Scroll) ? { $or: this.EquipmentFilter.selectedClass } : {},
              (this.EquipmentFilter.selectedStats.length > 0 && this.EquipmentFilter.selectedEquipmentType != this.equipmentType.Scroll) ? { $or: this.EquipmentFilter.selectedStats } : {}
            ]
          }
        };
        console.log(filterObject)
        await this.getEquipmentList(encodeURIComponent(JSON.stringify(filterObject)))

        if (this.EquipmentFilter.selectedEquipmentType === this.equipmentType.Scroll) {
          this.skillEquipments = []
          this.equipmentList.forEach((equipment) => {
            const filterSkill = {
              where: {
                $and: [
                  { "id": equipment.scroll.skill_id },
                  (this.EquipmentFilter.selectedClass.length > 0 && this.EquipmentFilter.selectedEquipmentType === this.equipmentType.Scroll) ? { $or: this.EquipmentFilter.selectedClass } : {},
                  (this.EquipmentFilter.selectedStats.length > 0 && this.EquipmentFilter.selectedEquipmentType === this.equipmentType.Scroll) ? { $or: this.EquipmentFilter.selectedStats } : {}
                ]
              }
            };
            this.checkSkill(encodeURIComponent(JSON.stringify(filterSkill)))
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                if (data.length !== 0) {
                  this.skillEquipments.push(equipment)
                }
              })

          });

        }



        if (this.EquipmentFilter.selectedEquipmentType === this.equipmentType.Scroll) {
          this.equipmentList = this.skillEquipments
        }
        this.equipmentList.sort(function (a, b) {
          return a.name.trim().localeCompare(b.name.trim(), undefined, { sensitivity: 'accent' });
        });

      },

      async getEquipmentList(filter) {
        this.equipmentList = await this.api.charequipment.getEquipmentList(filter);
      },

      async checkSkill(filter) {
        return await this.api.charequipment.verifySkill(filter);
      },

      async addEquipment() {
        this.model.character_id = this.data.entity.id
        this.errorList = []
        //validation
        if (this.EquipmentFilter.isExpiration && (this.model.expiration === 0 || this.model.expiration === null)) {
          this.errorList.push('Select valid Expiration')
        }
        if (this.model.quantity <= 0 || this.model.quantity == null) {
          this.errorList.push('Input valid number on quantity')
        }
        if (this.selectedEquipment == '') {
          this.errorList.push('Please select Equipment/Item')
        }
        if (this.errorList.length == 0) {
          ModalService.Show({
            title: `Add Equipment`,
            message: `Do you want to save ${this.selectedEquipment}?`
          }, async () => {
            let res = await this.api.charequipment.addEquipment(this.data.entity.id, this.server, this.model)
            if (res.message === 'Success') {
              ToastService.Notify({
                title: "Success",
                message: "Equipment Added!"
              });
              this.resetAll()
              this.showAdd = false;
              this.model = {
                hp: 0,
                energy: 0,
                level: 0,
                rv: 0,
                critRate: 0,
                hitRate: 0,
                evasion: 0,
                magicDefense: 0,
                element: 0,
                elementLevel: 0,
                fire: 0,
                ice: 0,
                poison: 0,
                electric: 0,
                quantity: 1,
                expiration: 0,
                isNonDrop: false,
                isEquiped: false,
              };
              this.selectedEquipment = ''

            }
            else {
              ToastService.Notify({
                title: "Error",
                message: "Unable to save."
              });
              this.showAdd = false;
            }
          });
        }
        else {
          this.showError = true;
        }
      },

      updateEquipments(entity) {
        this.equipmentToUpdate.entity = entity;
        this.equipmentToUpdate.show = true;
        this.equipmentToUpdate.isAdd = true;
      },

      updateEquipmentId() {
        const selectedOption = document.querySelector(`#equipment-list option[value='${this.selectedEquipment}']`);
        if (selectedOption) {
          this.model.equipment_id = parseInt(selectedOption.getAttribute("data-id"));
        }
      },

      async updateEquipment() {
        ModalService.Show({
          title: `Update Equipment`,
          message: `Do you want to update ${this.equipmentToUpdate.entity.equipment_name}?`
        }, async () => {
          delete this.equipmentToUpdate.entity.equipment_name
          delete this.equipmentToUpdate.entity.expirationDate
          const res = await this.api.charequipment.updateEquipment(this.equipmentToUpdate.entity.character_id, this.equipmentToUpdate.entity.id, this.server, this.equipmentToUpdate.entity)
          if (res.message === 'Success') {
            ToastService.Notify({
              title: "Success",
              message: "Equipment Saved!"
            });
            this.equipmentToUpdate.show = false;
          }
          else {
            ToastService.Notify({
              title: "Error",
              message: "Unable to save."
            });
            this.equipmentToUpdate.show = false;
          }
        });
      },

      closeUpdate() {
        this.equipmentToUpdate.show = false;
      },

      closeAdd() {
        this.showAdd = false;
        this.resetAll();
      },

      close() {
        let data = this.data;
        data.show = false;
      },

      resetAll() {

        this.EquipmentFilter.selectedEquipableTypes = []
        this.EquipmentFilter.selectedClass = []
        this.EquipmentFilter.selectedStats = []
        this.selectedEquipment = ''
        const checkboxes = document.querySelectorAll('input[id^="chk_"]');
        checkboxes.forEach(checkbox => {
          console.log(checkbox)
          checkbox.checked = false;
        });
      },

      //Time / Ticks Related
      getCurrentTicks() {
        const currentTimestamp = new Date().getTime();
        var ticks = currentTimestamp * 10000; // 10,000,000 nanoseconds in a tick
        return ticks + this.epochticks;
      },

      calculateTicksFromHours(hours) {
        const millisecondsToAdd = hours * 60 * 60 * 1000;
        return (millisecondsToAdd * 10000);
      },

      computeAdditionalTicks(ticks) {
        const currentTicks = this.getCurrentTicks()
        const expiration = currentTicks + ticks;
        if (this.equipmentToUpdate.show) {
          this.equipmentToUpdate.entity.expiration = expiration
          return
        }
        this.model.expiration = expiration
      },

      ticksToDate(ticks) {
        const milliseconds = (ticks - this.epochticks) * 0.0001;
        const date = new Date(milliseconds);
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
      },
    },
    async mounted() {
      this.popEquipments()
    },
  }
</script>

<style scoped>
  .modal {
    display: block;
  }
</style>